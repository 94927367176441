h1,
h2,
h3 {
  margin: 0;
}

img {
  image-rendering: pixelated;
}

.Toastify__toast button {
  width: auto;
}